import "@hotwired/turbo-rails"
import "./src/jquery";

import "./src/motos/css/bootstrap.min.css";
import "./src/motos/css/tobii.min.css";
import "./src/motos/css/tiny-slider.css";
import "./src/motos/css/materialdesignicons.min.css";
import "./src/motos/css/style.min.css";

import "./src/motos/js/bootstrap.bundle.min.js";
import "./src/motos/js/plugins.init.js";
import "./src/motos/js/app.js";
